// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-politics-tsx": () => import("./../../../src/pages/politics.tsx" /* webpackChunkName: "component---src-pages-politics-tsx" */),
  "component---src-pages-talent-tsx": () => import("./../../../src/pages/talent.tsx" /* webpackChunkName: "component---src-pages-talent-tsx" */),
  "component---src-pages-vida-y-acumulacion-tsx": () => import("./../../../src/pages/vida-y-acumulacion.tsx" /* webpackChunkName: "component---src-pages-vida-y-acumulacion-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */)
}

