import { ACTION_TYPES } from '../../constants/constants';

const showModalPostulation = (state = false, action) => {
  switch (action.type) {
    case ACTION_TYPES.SHOW_MODAL_POSTULATION:
      return action.newState;
    default:
      return state;
  }
};

export default showModalPostulation;
