export enum ACTION_TYPES {
  SHOW_MODAL_QUOTE = 'HANDLE_SHOW_MODAL_QUOTE',
  SHOW_MODAL_POSTULATION = 'HANDLE_SHOW_MODAL_POSTULATION',
}

export enum API_HUBSPOT {
  URL = 'https://6dkkcrkj7j.execute-api.us-east-1.amazonaws.com/dev/v1/proyectat/contact',
  ERROR_CONTACT_EXISTS = 'CONTACT_EXISTS',
  CONTACT_EXISTS_MESSSAGE = 'Tu correo ya ha sido registrado anteriormente',
  GENERAL_ERROR_MESSAGE = 'Error procesando tu solicitud',
  SUCCESS_MESSAGE = 'Tu solicitud ha sido registrada',
  POSTULATION_MESSAGE = 'Gracias por postularte!',
}

export const HEADERS_HUBSPOT = {
  'Content-Type': 'application/json',
};

export enum SWEET_MESSAGES {
  SUCCESS = 'Exito!',
  ERROR = 'Error!',
}
